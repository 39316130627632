.slick-slider {
    margin-bottom: 0px !important;
  }
  
  .slick-list {
    overflow: hidden;
  }
  
  .slick-track {
    display: flex;
  }
  