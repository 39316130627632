/* components/LoadingSpinner.css */
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* S'assure que le spinner est centré verticalement */
  }
  
  .spinner {
    border: 6px solid rgba(0, 0, 0, 0.1); /* Augmentez l'épaisseur de la bordure si nécessaire */
    border-top: 6px solid green; /* Changez la couleur de la bordure supérieure en vert */
    border-radius: 50%;
    width: 60px; /* Augmentez la largeur pour agrandir le spinner */
    height: 60px; /* Augmentez la hauteur pour agrandir le spinner */
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  